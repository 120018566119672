<template>
  <div>
    <vue-final-modal v-model="showModal" @click-outside="clickOutside" class="chat__context">

      <div class="chat__context__content">

        <!-- Current Message -->
        <div class="chat__context__message">
          <div v-if="chatItem.sender"class="chat__context__message__title">
            Ich ({{ chatItem.username }})
            <span>{{ chatItem.time | formatTimeChat }}</span>
          </div>
          <div v-else class="chat__context__message__title">
            {{ chatItem.username }}
            <span>{{ chatItem.time | formatTimeChat }}</span>
          </div>
          <p>{{ chatItem.text }}</p>
          <img v-if="chatEmbed && chatEmbed.embed && chatEmbed.embed.uri && selectedItemIsImage" :src="fileURL(chatEmbed.embed.uri)">
          <div v-else-if="chatEmbed && chatEmbed.embed && chatEmbed.embed.uri && !selectedItemIsImage" class="chat__item__message__file">
            <div class="chat__item__message__file__icon"></div>
            <div class="chat__item__message__file__text">
              <div class="chat__item__message__file__text__label">
                {{ selectedItemName }}
              </div>
              <div class="chat__item__message__file__text__sublabel">
                {{ selectedItemMeta }}
              </div>
            </div>
          </div>
        </div>

        <!-- Actions -->
        <div class="chat__context__list">

          <!-- Emoji Reaction -->
          <div class="chat__context__list__emojis" :class="containerClass">
            <div v-for="emoji in emojis" v-on:click="emojiClicked(emoji)" class="chat__context__list__emojis__item"
                 v-bind:class="{ 'chat__context__list__emojis__item--active': emojiActive(emoji) }">
              {{ emoji }}
            </div>
            <div v-on:click="expandEmojisClicked" class="chat__context__list__emojis__item chat__context__list__emojis__item--add" :class="iconClass">
              <img src="../../assets/chat/ic_16_plus.svg">
            </div>
            <div v-for="emoji in moreEmojis" v-on:click="emojiClicked(emoji)" class="chat__context__list__emojis__item" :class="moreEmojisClass">
              {{ emoji }}
            </div>
          </div>

          <!-- Common Actions -->
          <div v-on:click="replyClicked()" class="chat__context__list__item">
            <div class="chat__context__list__item__label">
              {{ $t('feed.reply') }}
            </div>
            <img src="@/assets/chat/ic_20_answer.svg" alt="">
          </div>
          <div v-if="copyAvailable" v-on:click="copyClicked()" class="chat__context__list__item">
            <div class="chat__context__list__item__label">
              {{ $t('feed.copy') }}
            </div>
            <img src="@/assets/chat/ic_20_copy.svg" alt="">
          </div>
          <div v-on:click="reportClicked()" class="chat__context__list__item">
            <div class="chat__context__list__item__label">
              {{ $t('feed.report') }}
            </div>
            <img src="@/assets/chat/ic_20_report.svg" alt="">
          </div>
          <div v-if="hasEmbeds && this.isSPMUser()" v-on:click="downloadFileClicked()" class="chat__context__list__item">
            <div class="chat__context__list__item__label">
              {{ $t('feed.share') }}
            </div>
            <img src="@/assets/chat/ic_20_download.svg" alt="">
          </div>
          <div v-if="selectedItemIsImage && !isApp()" v-on:click="sendImageClicked()" class="chat__context__list__item">
            <div class="chat__context__list__item__label">
              {{ $t('feed.open_image_new_tab') }}
            </div>
            <img src="@/assets/chat/ic_20_external.svg" alt="">
          </div>
          <div v-if="deleteAvailable" v-on:click="deleteClicked()" class="chat__context__list__item chat__context__list__item--delete">
            <div class="chat__context__list__item__label">
              {{ $t('feed.delete') }}
            </div>
            <img src="@/assets/chat/ic_20_delete.svg" alt="">
          </div>
          <div v-if="!deleteAvailable" v-on:click="ignoreClicked()" class="chat__context__list__item">
            <div class="chat__context__list__item__label">
              {{ $t('feed.delete') }}
            </div>
            <img src="@/assets/chat/ic_20_ignore.svg" alt="">
          </div>
          <div v-on:click="closeClicked()" class="chat__context__list__item">
            <div class="chat__context__list__item__label">
              {{ $t('feed.close') }}
            </div>
            <img src="@/assets/icons/ic_16_close.svg" alt="">
          </div>
        </div>

      </div>

    </vue-final-modal>
  </div>
</template>

<script>

import { CHAT_TEAMTIP } from '@/main'

export default {
  name: 'ChatContextMenu',
  props: {
    chatItem: Object,
    chatEmbed: Object,
    showModal: false
  },
  data () {
    return {
      showAllEmojis: false,
      emojis: [
        '👍', '❤️', '😂', '🚀', '😢️', '🙏', '👎','😀'
      ],
      moreEmojis:  ['😃','😄','😁','😆','😅','🤣','😊','😇','🙂','🙃','😉','😌','😍','🥰','😘','😗','😙','😚',
        '😋','😛','😝','😜','🤪','🤨','🧐','🤓','😎','🥸','🤩','🥳','😏','😒','😞','😔','😟','😕','🙁','☹️',
        '😣','😖','😫','😩','🥺','😭','😤','😠','😡','🤬','🤯','😳','🥵','🥶','😱','😨','😰','😥','😓',
        '😶','😐','😑','🫥','🫤','🙄','😬','🤥','🫨','😴','😪','😮‍💨','😮','😯','😲','🥱','😦','😧','😵','😵‍💫',
        '🤐','🫢','🥴','🤢','🤮','🤧','😷','🤒','🤕','💩','😺','😸','😹','😻','😼','😽','🙀','😿','😾','👋',
        '🤚','🖐️','✋','🖖','👌','✊','🤌','🤏','✌️','🤞','🫰','🤟','🤘','🤙','👈','👉','👆','🖕','👇','☝️','🫳',
        '🫴','👏','🙌','👐','🤲','🤝','✍️','💅','🤳','💪','🦾','🦿','🦵','🦶','👂','🦻','👃','🧠','🫀',
        '🫁','🦷','🦴','👀','👁️','👅','👄','🫦','👶','🧒','👦','👧','🧑','👱','👨','🧔','👩','🧓','👴','👵',
        '🙍','🙎','🙅','🙆','💁','🙋','🧏','🙇','🤦','🤷','🧑‍⚕️','🧑‍🎓','🧑‍🏫','🧑‍⚖️','🧑‍🌾','🧑‍🍳','🧑‍🔧','🧑‍🏭',
        '🧑‍💼','🧑‍🔬','🧑‍💻','🧑‍🎤','🧑‍🎨','🧑‍✈️','🧑‍🚀','🧑‍🚒','👮','🕵️','💂','🥷','👷','🤴','👸','👳','👲',
        '🧕','🤵','👰','🤰','🫄','🫃','🧎','🧍','💃','🕺','👯','🧖','🧘','👭','👫','👬','💏','💑','👪','🧳','🌂',
        '☂️','🧵','🧶','👓','🕶️','🥽','🥼','🦺','👔','👕','👖','🧣','🧤','🧥','🧦','👗','👘','🥻','🩱','🩲',
        '🩳','👙','👚','👛','👜','👝','🛍️','🎒','🩴','👞','👟','🥾','🥿','👠','👡','🩰','👢','👑','💋','💌',
        '💘','💝','💖','💗','💓','💞','💕','💟','❣️','🧡','💛','💚','💙','💜','🤎','🖤','🤍','💔','❤️‍🔥',
        '❤️‍🩹','🩵','🩷','💯','💢','💥','💫','💦','💨','🕳️','💣','💬','👁️‍🗨️','🗨️','🗯️','💭','🫂','🗣️','👻',
        '💀','☠️','👽','👾','🤖','🎃','🙈','🙉','🙊', '⚽','🏀','🏈','⚾','🎾','🏐','🏉','🥏','🎱','🏓','🏸','🥅',
        '🏒','🏑','🥍','🏏','🪃','🥊','🥋','🎽',
        '🛹','🛼','🛷','⛸️','🎿','⛷️','🏂','🤺','🤼','🤸','🤾','🏇','⛹️','🏌️','🏄','🏊','🤽','🚣','🧗','🚵',
        '🚴','🏆','🥇','🥈','🥉','🎖️','🏅','🇦🇫','🇦🇱','🇩🇿','🇦🇸','🇻🇮','🇺🇲','🇦🇩','🇦🇴','🇦🇮','🇦🇶','🇦🇬','🇦🇷','🇦🇲','🇦🇼','🇦🇿',
        '🇦🇺','🇧🇸','🇧🇭','🇧🇩','🇧🇧','🇧🇾','🇧🇪','🇧🇿','🇧🇯','🇧🇲','🇧🇹','🇧🇴','🇧🇦','🇧🇼','🇧🇻',
        '🇧🇷','🇻🇬','🇮🇴','🇧🇳','🇧🇬','🇧🇫','🇧🇮','🇨🇻','🇨🇱','🇨🇳','🇨🇰','🇨🇷','🇨🇼','🇨🇮','🇩🇪',
        '🇩🇲','🇩🇴','🇩🇯','🇩🇰','🇪🇨','🇸🇻','🏴󠁧󠁢󠁥󠁮󠁧󠁿󠁿','🇪🇷','🇪🇪','🇸🇿','🇫🇰','🇫🇯','🇫🇮','🇫🇷','🇬🇫',
        '🇵🇫','🇹🇫','🇫🇴','🇬🇦','🇬🇲','🇬🇪','🇬🇭','🇬🇮','🇬🇩','🇬🇷','🇬🇱','🇬🇵','🇬🇺','🇬🇹','🇬🇬',
        '🇬🇳','🇬🇼','🇬🇾','🇭🇹','🇭🇲','🇭🇳','🇮🇳','🇮🇩','🇮🇶','🇮🇷','🇮🇪','🇮🇸','🇮🇲','🇮🇱','🇮🇹',
        '🇯🇲','🇯🇵','🇾🇪','🇯🇪','🇯🇴','🇰🇾','🇰🇭','🇨🇲','🇨🇦','🇧🇶','🇰🇿','🇶🇦','🇰🇪','🇰🇬','🇰🇮',
        '🇨🇨','🇨🇴','🇰🇲','🇨🇬','🇨🇩','🇭🇷','🇨🇺','🇰🇼','🇱🇦','🇱🇸','🇱🇻','🇱🇧','🇱🇷','🇱🇾','🇱🇮',
        '🇱🇹','🇱🇺','🇲🇬','🇲🇼','🇲🇾','🇲🇻','🇲🇱','🇲🇹','🇲🇦','🇲🇭','🇲🇶','🇲🇷','🇲🇺','🇾🇹','🇲🇽',
        '🇫🇲','🇲🇨','🇲🇳','🇲🇪','🇲🇸','🇲🇿','🇲🇲','🇳🇦','🇳🇷','🇳🇵','🇳🇨','🇳🇿','🇳🇮','🇳🇱','🇳🇪',
        '🇳🇬','🇳🇺','🇰🇵','🇲🇰','🇳🇫','🇳🇴','🇲🇵','🇴🇲','🇵🇰','🇵🇼','🇵🇸','🇵🇦','🇵🇬','🇵🇾',
        '🇵🇪','🇵🇭','🇵🇳','🇵🇱','🇵🇹','🇵🇷','🇲🇩','🇷🇼','🇷🇴','🇷🇺','🇷🇪','🇸🇧','🇿🇲','🇼🇸','🇸🇲',
        '🇸🇦','🏴󠁧󠁢󠁳󠁣󠁴󠁿','🇸🇪','🇨🇭','🇸🇳','🇷🇸','🇸🇨','🇸🇱','🇿🇼','🇸🇬','🇸🇽','🇸🇰','🇸🇮','🇸🇴','🇭🇰',
        '🇲🇴','🇪🇸','🇸🇯','🇱🇰','🇧🇱','🇸🇭','🇰🇳','🇱🇨','🇲🇫','🇵🇲','🇻🇨','🇸🇩','🇸🇷','🇸🇾','🇸🇹',
        '🇿🇦','🇬🇸','🇰🇷','🇸🇸','🇹🇯','🇹🇼','🇹🇿','🇹🇭','🇹🇱','🇹🇬','🇹🇰','🇹🇴','🇹🇹','🇹🇩','🇨🇿',
        '🇹🇳','🇹🇲','🇹🇨','🇹🇻','🇹🇷','🇺🇬','🇺🇦','🇭🇺','🇺🇾','🇺🇿','🇻🇺','🇻🇦','🇻🇪','🇦🇪','🇺🇸',
        '󠁧󠁧󠁿🏴󠁧󠁢󠁷󠁬󠁳󠁿','🇻🇳','🇼🇫','🇨🇽','🇪🇭','🇨🇫','🇨🇾','🇪🇬','🇬🇶','🇪🇹','🇦🇽','🇦🇹','🇽🇰'
      ]
    }
  },
  mounted () {
  },
  methods: {
    closeClicked() {
      this.showAllEmojis = false
      this.$emit('emojiClicked', { selected: null } )
    },
    downloadFileClicked() {
      this.showAllEmojis = false
      if(this.chatEmbed && this.chatEmbed.embed) {
        this.$emit('downloadFileClicked', { selected: this.chatEmbed.embed })
      }
    },
    fileURL(file) {
      return CHAT_TEAMTIP + file
    },
    ignoreClicked() {
      this.showAllEmojis = false
      this.$emit('ignoreClicked', { selected: null } )
    },
    sendImageClicked() {
      this.showAllEmojis = false
      if(this.chatEmbed && this.chatEmbed.embed) {
        this.$emit('sendImageClicked', { selected: this.chatEmbed.embed })
      }
    },
    emojiActive(emoji) {
      const reactions = this.chatItem.reactions ? this.chatItem.reactions : []
      for (let i = 0; i < reactions.length; i++) {
        if(reactions[i].text === emoji && parseInt(reactions[i].user_id) === this.$store.state.user.id) {
          return true        
        }
      }
      return false
    },
    reportClicked() {
      this.showAllEmojis = false
      this.$emit('reportClicked', { selected: null } )
    },
    copyClicked() {
      this.showAllEmojis = false
      this.$emit('copyClicked', { selected: null } )
    },
    replyClicked() {
      this.showAllEmojis = false
      this.$emit('replyClicked', { selected: null } )
    },
    deleteClicked() {
      this.showAllEmojis = false
      this.$emit('deleteClicked', { selected: null } )
    },
    clickOutside() {
      this.showAllEmojis = false
      this.$emit('emojiClicked', { selected: null } )
    },
    emojiClicked(emoji) {
      this.showAllEmojis = false
      this.$emit('emojiClicked', { selected: emoji } )
    },
    expandEmojisClicked() {
      this.showAllEmojis = !this.showAllEmojis
    }
  },
  computed: {
    selectedItemName() {
      if(this.chatEmbed && this.chatEmbed.embed) {
        return this.chatEmbed.embed.filename
      }
      return ''
    },
    selectedItemMeta() {
      if(this.chatEmbed && this.chatEmbed.embed) {
        return this.getChatEmbedInfo(this.chatEmbed.embed)
      }
      return ''
    },
    hasEmbeds() {
      return this.chatItem && this.chatItem.embeds && this.chatItem.embeds.length > 0
    },
    selectedItemIsImage() {
      if(this.chatItem && this.chatItem.embeds && this.chatItem.embeds.length > 0) {
        const t = this.chatItem.embeds.filter((embed) => {
          return embed.mimetype.startsWith('image/')
        })
        return t.length > 0
      }
      return false
    },
    deleteAvailable() {
      if(!this.chatItem || !this.chatItem.timestamp) {
        return false
      }
      const d = Date.now() - parseInt(this.chatItem.timestamp)
      const week = 1000 * 60 * 60 * 24 * 7
      return this.chatItem.sender && d < week
    },
    copyAvailable() {
      return !this.chatItem.embeds || this.chatItem.embeds.length === 0
    },
    containerClass() {
      return this.showAllEmojis ? 'chat__context__list__emojis--expanded' : ''
    },
    iconClass() {
      return !this.showAllEmojis ? '' : 'chat__context__list__emojis__item--invisible'
    },
    moreEmojisClass() {
      return !this.showAllEmojis ? 'chat__context__list__emojis__item--invisible' : ''
    }
  }
}
</script>

<style lang="scss">
.chat__context {
  display: flex;

  .vfm--inset {
    display: flex;
  }

  .vfm__content {
    display: flex;
    margin: auto auto 0 auto;
    width: 100%;
    max-width: 576px;

    @media screen and (min-width: 768px) {
      margin: auto;
    }
  }

  .vfm--overlay {
    backdrop-filter: blur(4px);
  }

  &__content {
    width: 100%;
    padding: 16px;
    box-sizing: border-box;
    user-select: none;
    -ms-user-select: none;
    -moz-user-select: none;
    -webkit-user-select: none;
    
    .vfm-enter-active & {
      animation: .2s enterAnimation !important;
      animation-fill-mode: forwards !important;
    }

    @keyframes enterAnimation {
      from {  
        transform: translateY(48px)
      }
      to {
        transform: translateY(0)
      }
    }

    .vfm-leave-active & {
      animation: .2s leaveAnimation ease-in-out !important;
      animation-fill-mode: forwards !important;
    }

    @keyframes leaveAnimation {
      from {  
        transform: translateY(0)
      }
      to {
        transform: translateY(48px)
      }
    }
  }

  &__message {
    background: $colorWhite;
    padding: 21px 24px 18px;
    border-radius: 4px 24px 24px 24px;
    width: 100%;
    box-sizing: border-box;

    &__title {
      display: flex;
      font-size: 14px;
      font-weight: 500;
      color: $colorGreyDark;
      margin-bottom: 8px;

      .chat__item--sender & {
        color: $colorWhite;
      }

      span {
        margin-top: 3px;
        font-size: 11px;
        font-weight: 400;
        opacity: .48;
        margin-left: auto;
      }
    }

    p {
      font-size: 16px;
      line-height: 22px;
      word-break: break-word;

      @media screen and (max-width: 479px) {
        display: -webkit-box;
        -webkit-line-clamp: 8;
        -webkit-box-orient: vertical;  
        overflow: hidden;
      }
    }

    img {
      width: calc(100% + 16px);
      height: auto;
      max-height: 150px;
      margin: 0 -8px -8px;
      border-radius: 8px;
      object-fit: contain;
      background: rgba($colorGreyDark, .08);
    }
  }

  &__list {
    display: flex;
    flex-direction: column;
    background: $colorWhite;
    border-radius: 8px;
    margin-top: 12px;
    overflow-y: scroll;

    &__emojis {
      display: grid;
      grid-template-columns: repeat(9, 1fr);
      grid-template-rows: repeat(1, 1fr);
      grid-row-gap: 8px;
      padding: 5px;
      margin: 4px 0;
      max-height: 160px;
      overflow-y: scroll;
      overflow-x: hidden;

      &--expanded {
        position: relative;
        box-shadow: inset 0 -1px 0 0 rgba($colorGreyDark, .08);

        &:after {
          position: sticky;
          content: '';
          bottom: -5px;
          margin: -5px;
          grid-column: span 9;
          height: 32px;
          background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.64) 100%);
        }
      }

      &__item {
        font-size: 22px;
        line-height: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        width: auto;
        max-width: 40px;
        height: 40px;
        border-radius: 50%;
        transition: all .2s ease-in-out;

        &--invisible {
          display: none;
        }

        &--active, &:hover {
          background: rgba(#000000, .08);
        }

        &--add {
          width: 24px;
          height: 24px;
          margin: 8px;
          background: rgba(#000000, .04);

          img {
            opacity: .64;
          }

          &:hover {
            img {
              opacity: .8;
            }
          }
        }
      }
    }

    &__item {
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-size: 14px;
      line-height: 20px;
      font-weight: 500;
      color: $colorGreyDark;
      padding: 12px 16px;
      transition: all .2s ease-in-out;

      &:hover {
        background: rgba(#000000, .04);
      }

      &:not(:last-child) {
        box-shadow: inset 0 -1px 0 0 rgba($colorGreyDark, .08);
      }

      &--delete {
        color: $colorNegative;
      }

      &__label {
        flex: 1;
      }

      &__avatar {
        position: relative;
        flex-shrink: 0;
        width: 32px;
        height: 32px;
        border-radius: 50%;
        margin: -2px 8px -2px 0;
        overflow: hidden;
        background: rgba($colorGreyDark, .08);

        &:before {
          position: absolute;
          z-index: 1;
          top: 8px;
          left: 8px;
          width: 16px;
          height: 16px;
          content: url('../../assets/chat/ic_16_user.svg');
          opacity: .48;
        }

        img {
          position: relative;
          z-index: 2;
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }

      span {
        font-size: 22px;
      }

      > img {
        width: 20px;
        height: 20px;
      }
    }
  }
}
</style>
